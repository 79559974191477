<template>
  <Header />
  <div class="privacy-policy">
    <!-- Banner Section -->
    <InnerBanner
      title="Privacy Policy"
      description="Your privacy is important to us"
    />

    <!-- Content Section -->
    <div class="container mx-auto px-5 pt-50">
      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4">Introduction</h2>
        <p class="text-gray-700 leading-relaxed">
          At ReversePilot, we value your privacy. This privacy policy outlines
          the types of personal information we collect, how we use and protect
          it, and your rights regarding your information.
        </p>
      </section>

      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4">Information Collection</h2>
        <p class="text-gray-700 leading-relaxed">
          We collect information you provide directly to us, such as when you
          create an account, subscribe to our newsletter, or contact us. This
          information may include your name, email address, phone number, and
          other contact details.
        </p>
      </section>

      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4">Information Use</h2>
        <p class="text-gray-700 leading-relaxed">
          We use the information we collect to provide, maintain, and improve
          our services, communicate with you, and protect our users. We may also
          use the information for research and analytics purposes.
        </p>
      </section>

      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4">Information Sharing</h2>
        <p class="text-gray-700 leading-relaxed">
          We do not share your personal information with third parties except as
          described in this privacy policy. We may share information with
          vendors and service providers who perform services on our behalf, and
          as required by law.
        </p>
      </section>

      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4">Your Rights</h2>
        <p class="text-gray-700 leading-relaxed">
          You have the right to access, correct, and delete your personal
          information. You can also object to the processing of your information
          and request that we restrict the processing of your information.
        </p>
      </section>

      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4">Contact Us</h2>
        <p class="text-gray-700 leading-relaxed">
          If you have any questions about this privacy policy or our privacy
          practices, please contact us at
          <a href="mailto:info@reversepilot.com" class="text-primary"
            >info@reversepilot.com</a
          >.
        </p>
      </section>
    </div>
  </div>
  <Footer />
</template>

<script>
import Footer from "../../components/Footer/index.vue";
import Header from "../../components/Header/index.vue";
import InnerBanner from "../../components/InnerBanner/index.vue";
export default {
  name: "PrivacyPolicy",
  components: {
    Header,
    Footer,
    InnerBanner,
  },
};
</script>
