import { createRouter, createWebHistory } from "vue-router";
import Web from "../pages/web/index.vue";
import TermsCondition from "../pages/TermsCondition/index.vue";
import PrivacyPolicy from "../pages/PrivacyPolicy/index.vue";
import Registration from "../pages/Registration/index.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Web,
  },
  {
    path: "/signup",
    name: "registration",
    component: Registration,
  },
  {
    path: "/privacy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/terms",
    name: "TermsConditions",
    component: TermsCondition,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
