<template>
  <section
    id="services"
    class="bg-[#F2F7FA] flex items-center justify-center py-50 sm:pt-50 md:pt-70 lg:pt-100 lg:pb-[180px] md:pb-70 sm:pb-70"
  >
    <div class="container">
      <div
        class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 items-center"
      >
        <h2 class="text-heading font-bold text-primary">
          Go Full Throttle with Our Powerful Engines
        </h2>
        <p>
          Our multi-engine platform is modular, allowing you to use our powerful
          automation or stick with your existing processes.
        </p>
      </div>
      <div
        class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mt-10"
      >
        <div class="box-section rounded-10 pt-12 pb-9 px-9 bg-primary">
          <div class="icon-img flex justify-center items-center mx-auto">
            <img src="../../components/img/engine.png" />
          </div>
          <h3 class="text-center text-white font-semibold text-22 mt-4 mb-3.5">
            E-Sign Engine
          </h3>
          <p class="text-center text-white">
            Streamline the signing process with our E-Sign Engine, enabling
            secure and efficient electronic signatures. Simplify document
            completion and accelerate the loan process for your clients.
          </p>
          <Button variant="secondary" class="block mx-auto mt-5"
            >Read More</Button
          >
        </div>
        <div
          class="box-section rounded-10 pt-12 pb-9 px-9 shadow-card bg-secondary"
        >
          <div class="icon-img flex justify-center items-center mx-auto">
            <img src="../../components/img/api.png" />
          </div>
          <h3 class="text-center text-white font-semibold text-22 mt-4 mb-3.5">
            API Engine
          </h3>
          <p class="text-center text-white">
            Integrate seamlessly with existing systems through our robust API
            Engine. Or use pre-built integrations to connect your business
            processes to our software. Facilitate smooth data exchange across
            your mortgage technology stack.
          </p>
          <Button class="block mx-auto mt-5">Read More</Button>
        </div>
        <div
          class="box-section rounded-10 pt-12 pb-9 px-9 shadow-card bg-primary"
        >
          <div class="icon-img flex justify-center items-center mx-auto">
            <img src="../../components/img/doc-box.png" />
          </div>
          <h3 class="text-center text-white font-semibold text-22 mt-4 mb-3.5">
            Document Engine
          </h3>
          <p class="text-center text-white">
            Manage all your documents with ease using our Document Engine.
            Ensure seamless storage, retrieval, and sharing of critical loan
            documents, all within a secure and compliant framework.
          </p>
          <Button variant="secondary" class="block mx-auto mt-5"
            >Read More</Button
          >
        </div>
        <div
          class="box-section rounded-10 pt-12 pb-9 px-9 shadow-card bg-secondary"
        >
          <div class="icon-img flex justify-center items-center mx-auto">
            <img src="../../components/img/project.png" />
          </div>
          <h3 class="text-center text-white font-semibold text-22 mt-4 mb-3.5">
            Calculation Engine
          </h3>
          <p class="text-center text-white">
            Trust our Calculation Engine for accurate and efficient loan
            calculations. From APR to closing costs, get precise results that
            help you stay compliant and competitive.
          </p>
          <Button class="block mx-auto mt-5">Read More</Button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Button from "../../../../components/Button/index.vue";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Services",
  components: { Button },
};
</script>
<style scoped>
.box-section:nth-child(2n) {
  margin-top: 70px;
  margin-bottom: -70px;
}
@media (max-width: 768px) {
  .box-section:nth-child(2n) {
    margin-top: 0;
    margin-bottom: 0;
  }
}
</style>
