<template>
  <Loading />
  <router-view />
</template>

<script>
import Loading from "./components/Loading/index.vue";
export default {
  name: "App",
  components: { Loading },
};
</script>
