<template>
  <section
    id="about"
    class="flex items-center justify-center pt-0 pb-50 lg:pb-100 md:pb-70 sm:pb-50"
  >
    <div class="container">
      <div
        class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5 mt-10 items-center"
      >
        <div class="sm:mx-auto">
          <img src="../../components/img/about-img.png" />
        </div>
        <div>
          <h2 class="text-heading font-bold text-primary">
            Comprehensive Reporting Engine for Unmatched Insights
          </h2>
          <p class="my-5">
            Our advanced reporting engine delivers detailed insights into both
            compliance and business operations. Effortlessly generate HMDA
            reports and NMLS call reports to ensure regulatory compliance and
            transparency. Additionally, harness real-time data customizable
            reports to drive business analytics, empowering your team to make
            decisions and optimize performance with ReversePilot.
          </p>
          <Button variant="secondary">Discover More</Button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Button from "../../../../components/Button/index.vue";
export default {
  name: "AboutUs",
  components: { Button },
};
</script>
<style scoped></style>
