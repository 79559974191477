<template>
  <Header />
  <HeroSection />
  <ProcessSection />
  <AboutUs />
  <Services />
  <Testimonials />
  <!-- <Blog /> -->
  <ContactUs />
  <Footer />
</template>

<script>
import Header from "./../../components/Header/index.vue";
import Footer from "./../../components/Footer/index.vue";
import HeroSection from "./components/HeroSection/index.vue";
import ProcessSection from "./components/ProcessSection/index.vue";
import AboutUs from "./components/AboutUs/index.vue";
import Services from "./components/Services/index.vue";
import Testimonials from "./components/Testimonials/index.vue";
import ContactUs from "./components/ContactUs/index.vue";
// import Blog from "./components/Blog/index.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Web",
  components: {
    Header,
    HeroSection,
    ProcessSection,
    AboutUs,
    Services,
    Testimonials,
    // Blog,
    ContactUs,
    Footer,
  },
};
</script>
