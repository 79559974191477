<template>
  <Header />
  <div class="terms-conditions">
    <!-- Banner Section -->
    <InnerBanner
      title="Terms Of Services"
      description="These are our terms of services"
    />

    <!-- Content Section -->
    <div class="container mx-auto px-5 pt-50">
      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4">Introduction</h2>
        <p class="text-gray-700 leading-relaxed">
          Welcome to ReversePilot. These terms and conditions outline the rules
          and regulations for the use of ReversePilot's Website.
        </p>
      </section>

      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4">Intellectual Property Rights</h2>
        <p class="text-gray-700 leading-relaxed">
          Other than the content you own, under these Terms, ReversePilot and/or
          its licensors own all the intellectual property rights and materials
          contained in this Website.
        </p>
      </section>

      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4">Restrictions</h2>
        <p class="text-gray-700 leading-relaxed">
          You are specifically restricted from all of the following:
        </p>
        <ul class="list-disc list-inside ml-5 text-gray-700">
          <li>Publishing any Website material in any other media</li>
          <li>
            Selling, sublicensing and/or otherwise commercializing any Website
            material
          </li>
          <li>Publicly performing and/or showing any Website material</li>
          <li>
            Using this Website in any way that is or may be damaging to this
            Website
          </li>
          <li>
            Using this Website in any way that impacts user access to this
            Website
          </li>
          <li>
            Using this Website contrary to applicable laws and regulations
          </li>
          <li>
            Engaging in any data mining, data harvesting, data extracting or any
            other similar activity in relation to this Website
          </li>
          <li>Using this Website to engage in any advertising or marketing</li>
        </ul>
      </section>

      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4">Your Content</h2>
        <p class="text-gray-700 leading-relaxed">
          In these Website Standard Terms and Conditions, "Your Content" shall
          mean any audio, video, text, images or other material you choose to
          display on this Website. By displaying Your Content, you grant
          ReversePilot a non-exclusive, worldwide irrevocable, sub-licensable
          license to use, reproduce, adapt, publish, translate and distribute it
          in any and all media.
        </p>
      </section>

      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4">No warranties</h2>
        <p class="text-gray-700 leading-relaxed">
          This Website is provided "as is," with all faults, and ReversePilot
          express no representations or warranties, of any kind related to this
          Website or the materials contained on this Website. Also, nothing
          contained on this Website shall be interpreted as advising you.
        </p>
      </section>

      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4">Limitation of liability</h2>
        <p class="text-gray-700 leading-relaxed">
          In no event shall ReversePilot, nor any of its officers, directors,
          and employees, be held liable for anything arising out of or in any
          way connected with your use of this Website whether such liability is
          under contract. ReversePilot, including its officers, directors, and
          employees shall not be held liable for any indirect, consequential, or
          special liability arising out of or in any way related to your use of
          this Website.
        </p>
      </section>

      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4">Indemnification</h2>
        <p class="text-gray-700 leading-relaxed">
          You hereby indemnify to the fullest extent ReversePilot from and
          against any and/or all liabilities, costs, demands, causes of action,
          damages, and expenses arising in any way related to your breach of any
          of the provisions of these Terms.
        </p>
      </section>

      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4">Severability</h2>
        <p class="text-gray-700 leading-relaxed">
          If any provision of these Terms is found to be invalid under any
          applicable law, such provisions shall be deleted without affecting the
          remaining provisions herein.
        </p>
      </section>

      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4">Variation of Terms</h2>
        <p class="text-gray-700 leading-relaxed">
          ReversePilot is permitted to revise these Terms at any time as it sees
          fit, and by using this Website you are expected to review these Terms
          on a regular basis.
        </p>
      </section>

      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4">Assignment</h2>
        <p class="text-gray-700 leading-relaxed">
          The ReversePilot is allowed to assign, transfer, and subcontract its
          rights and/or obligations under these Terms without any notification.
          However, you are not allowed to assign, transfer, or subcontract any
          of your rights and/or obligations under these Terms.
        </p>
      </section>

      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4">Entire Agreement</h2>
        <p class="text-gray-700 leading-relaxed">
          These Terms constitute the entire agreement between ReversePilot and
          you in relation to your use of this Website and supersede all prior
          agreements and understandings.
        </p>
      </section>

      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4">Governing Law & Jurisdiction</h2>
        <p class="text-gray-700 leading-relaxed">
          These Terms will be governed by and interpreted in accordance with the
          laws of the State of [Your State], and you submit to the non-exclusive
          jurisdiction of the state and federal courts located in [Your State]
          for the resolution of any disputes.
        </p>
      </section>
    </div>
  </div>
  <Footer />
</template>

<script>
import Footer from "../../components/Footer/index.vue";
import Header from "../../components/Header/index.vue";
import InnerBanner from "../../components/InnerBanner/index.vue";

export default {
  name: "TermsConditions",
  components: {
    Header,
    Footer,
    InnerBanner,
  },
};
</script>
