<template>
  <header
    class="bg-primary text-white py-5 flex justify-between items-center px-[90px] fixed w-full z-10"
  >
    <div>
      <img src="./../../assets/header-logo.svg" alt="logo" />
    </div>
    <nav class="hidden lg:flex justify-center space-x-10">
      <a href="#home" class="font-bold" v-smooth-scroll>Home</a>
      <a href="#about" class="font-bold" v-smooth-scroll>About Us</a>
      <a href="#services" class="font-bold" v-smooth-scroll>Services</a>
      <a href="#testimonials" class="font-bold" v-smooth-scroll>Testimonials</a>
      <!-- <a href="#blog" class="font-bold" v-smooth-scroll>Blog</a> -->
      <a href="#contact" class="font-bold" v-smooth-scroll>Contact Us</a>
    </nav>
    <div class="hidden lg:flex">
      <Button variant="outline" class="mr-15">Login</Button>
      <Button variant="secondary" @click="goToSignUp">Sign Up</Button>
    </div>
    <!-- Burger Menu Button -->
    <div class="lg:hidden flex items-center">
      <button @click="toggleMenu" class="text-24">
        <font-awesome-icon :icon="['fas', 'bars']" />
      </button>
    </div>
    <!-- Full Screen Menu -->
    <transition name="slide">
      <div
        v-if="isMenuOpen"
        class="fixed top-0 left-0 w-full h-full bg-primary text-white flex flex-col items-center justify-center space-y-5"
      >
        <button
          @click="closeMenu"
          class="absolute top-0 right-0 mx-5 my-4 text-xl font-bold cursor-pointer text-24"
        >
          <font-awesome-icon :icon="['fas', 'xmark']" />
        </button>
        <a href="#home" class="font-bold" v-smooth-scroll @click="closeMenu"
          >Home</a
        >
        <a href="#about" class="font-bold" v-smooth-scroll @click="closeMenu"
          >About Us</a
        >
        <a href="#services" class="font-bold" v-smooth-scroll @click="closeMenu"
          >Services</a
        >
        <a
          href="#testimonials"
          class="font-bold"
          v-smooth-scroll
          @click="closeMenu"
        >
          Testimonials</a
        >
        <a href="#blog" class="font-bold" v-smooth-scroll>Blog</a>
        <a href="#contact" class="font-bold" v-smooth-scroll>Contact Us</a>
        <div>
          <Button variant="outline" class="mr-15" @click="closeMenu"
            >Login</Button
          >
          <Button variant="secondary" @click="goToSignUp">Sign Up</Button>
        </div>
      </div>
    </transition>
  </header>
</template>

<script>
import Button from "../Button/index.vue";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Header",
  components: {
    Button,
  },
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    goToSignUp() {
      this.$router.push({ name: "registration" });
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
  },
};
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
}
@media (max-width: 1440px) {
  header {
    padding: 20px;
  }
  nav a {
    margin-right: 20px !important;
    margin-left: 0 !important;
  }
}
</style>
