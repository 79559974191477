<template>
  <section
    class="flex items-center justify-center pt-[200px] lg:pb-100 md:pb-0"
  >
    <div class="container">
      <div
        class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 items-center"
      >
        <h2 class="text-heading font-bold text-primary">
          From Sales to Post-Closing: Your All-in-One Solution
        </h2>
        <p>
          ReversePilot navigates every phase of the reverse mortgage process,
          ensuring seamless communication and efficiency from sales through to
          post-closing. Our all-in-one solution is the co-pilot your team needs
          to soar above the competition, delivering exceptional service and
          ensuring compliance every step of the way.
        </p>
      </div>
      <div
        class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mt-10"
      >
        <div
          class="box-section rounded-10 pt-12 pb-9 px-9 shadow-card hover:bg-primary"
        >
          <div class="icon-img flex justify-center items-center mx-auto">
            <img src="../../components/img/clock.png" />
          </div>
          <h3 class="text-center font-semibold text-22 mt-6 mb-3.5">
            Work In Real Time
          </h3>
          <p class="text-center">
            Collaborate with your team in real time using our web based
            platform. Multiple team members can work on the same loan, at the
            same time. No more file locks or software crashes.
          </p>
        </div>
        <div
          class="box-section rounded-10 pt-12 pb-9 px-9 shadow-card hover:bg-primary"
        >
          <div class="icon-img flex justify-center items-center mx-auto">
            <img src="../../components/img/document.png" />
          </div>
          <h3 class="text-center font-semibold text-22 mt-6 mb-3.5">
            Powerful Document Management
          </h3>
          <p class="text-center">
            Create custom sales and legal documents on-the-fly, utilizing
            ReversePilot's industry-leading document management solution.
          </p>
        </div>
        <div
          class="box-section rounded-10 pt-12 pb-9 px-9 shadow-card hover:bg-primary"
        >
          <div class="icon-img flex justify-center items-center mx-auto">
            <img src="../../components/img/settings.png" />
          </div>
          <h3 class="text-center font-semibold text-22 mt-6 mb-3.5">
            Fully Integrated Solution
          </h3>
          <p class="text-center">
            ReversePilot integrates directly with title companies, FHA
            Connection, MERS, flood certificate, and credit vendors. Save time
            and money by having full access within your LOS.
          </p>
        </div>
        <div
          class="box-section rounded-10 pt-12 pb-9 px-9 shadow-card hover:bg-primary"
        >
          <div class="icon-img flex justify-center items-center mx-auto">
            <img src="../../components/img/pen.png" />
          </div>
          <h3 class="text-center font-semibold text-22 mt-6 mb-3.5">
            Robust Compliance Controls
          </h3>
          <p class="text-center">
            Individual field locking, historical reporting, and licensing
            controls barely scratch the surface of ReversePilot's best-in-class
            compliance management tools.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProcessSection",
};
</script>
<style scoped>
.box-section {
  transition: all 0.3s ease-in-out;
}
.box-section:hover h3,
.box-section:hover p {
  color: #fff;
}
.icon-img {
  position: relative;
  width: 110px;
  height: 110px;
  border: 1px solid #cbf6ff;
  background: #f2f8fa;
  border-radius: 29px;
  transition: all 0.3s ease-in-out;
}
.icon-img::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 29px;
  background: #e78724;
  transform: translate(0);
  z-index: -1;
}

.box-section:hover .icon-img:before {
  transform: translate(-10px, 10px);
}
.box-section:hover .icon-img {
  background-color: #fff;
}
.box-section:nth-child(2n) {
  margin-top: 70px;
  margin-bottom: -70px;
}
@media (max-width: 768px) {
  .box-section:nth-child(2n) {
    margin-top: 0;
    margin-bottom: 0;
  }
}
</style>
