<template>
  <div class="preloader" v-if="isLoading">
    <div class="spinner"></div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Loading",
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    window.addEventListener("load", this.handleLoad);
  },
  beforeUnmount() {
    window.removeEventListener("load", this.handleLoad);
  },
  methods: {
    handleLoad() {
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #e78724;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
