<template>
  <button :class="buttonClass" class="w-auto" @click="handleClick">
    <slot></slot>
  </button>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Button",
  props: {
    type: {
      type: String,
      default: "button",
    },
    variant: {
      type: String,
      default: "primary",
    },
    size: {
      type: String,
      default: "medium",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonClass() {
      return [
        "standard-btn",
        this.variantClass,
        this.sizeClass,
        { "btn-disabled": this.disabled },
      ];
    },
    variantClass() {
      switch (this.variant) {
        case "primary":
          return "btn-primary";
        case "secondary":
          return "btn-secondary";
        case "danger":
          return "btn-danger";
        case "outline":
          return "btn-outline";
        default:
          return "btn-primary";
      }
    },
    sizeClass() {
      switch (this.size) {
        case "small":
          return "btn-small";
        case "large":
          return "btn-large";
        default:
          return "btn-medium";
      }
    },
  },
  methods: {
    handleClick(event) {
      if (!this.disabled) {
        this.$emit("confirm", event); // Emit a custom event
      }
    },
  },
};
</script>

<style scoped>
.standard-btn {
  position: relative;
  overflow: hidden;
  font-size: 16px;
  padding: 13px 24px;
  border-radius: 5px;
  font-weight: 500;
  z-index: 1;
}

.standard-btn::after {
  background: #fff;
  content: "";
  height: 155px;
  left: -75px;
  opacity: 0.2;
  position: absolute;
  top: -50px;
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
  transition: all 0.55s cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
  z-index: -10;
}
.standard-btn:hover::after {
  left: 120%;
  transition: all 0.55s cubic-bezier(0.19, 1, 0.22, 1);
}

.standard-btn.btn-primary {
  background-color: #104862;
  color: #fff;
}

.standard-btn.btn-secondary {
  background-color: #e78724;
  color: #fff;
}

.standard-btn.btn-danger {
  background-color: #ff3737;
  color: #fff;
}

.standard-btn.btn-outline {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.standard-btn.btn-disabled {
  background-color: #d6d6d6;
  color: #999;
  cursor: not-allowed;
}
@media (max-width: 1440px) {
  .standard-btn {
    padding: 9px 24px;
  }
}
</style>
