<template>
  <section
    id="contact"
    class="bg-[#F2F7FA] pt-5 pb-50 lg:pt-80 lg:pb-100 md:pb-70 sm:pb-50"
  >
    <div class="container">
      <div
        class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-[30px] mt-10"
      >
        <div class="bg-white shadow-card rounded-[20px] p-10">
          <h2 class="text-30 font-bold text-black mb-2">Get In Touch</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipiscing elit. Mauris nunc
            semper nulla faucibus.
          </p>
          <div class="mt-10">
            <!-- <div class="flex border-b border-[#E4E8EF] mb-5 pb-5">
              <div class="icon-box mr-5">
                <font-awesome-icon icon="fa-solid fa-location-dot" />
              </div>
              <div>
                <h5 class="font-bold text-lg mb-1">Visit us :</h5>
                <p>27 Division St, New York, NY 10002, USA</p>
              </div>
            </div> -->
            <div class="flex border-[#E4E8EF] mb-5 pb-5">
              <div class="icon-box mr-5">
                <font-awesome-icon icon="fa-solid fa-envelope" />
                <!-- <font-awesome-icon icon="fa-solid fa-phone" /> -->
              </div>
              <div>
                <h5 class="font-bold text-lg mb-1">Mail us :</h5>
                <p>
                  <a href="mailto:info@reversepilot.com"
                    >info@reversepilot.com</a
                  >
                </p>
              </div>
            </div>
            <!-- <div class="flex">
              <div class="icon-box mr-5">
                <font-awesome-icon icon="fa-solid fa-phone" />
              </div>
              <div>
                <h5 class="font-bold text-lg mb-1">Call us :</h5>
                <p>+1 (800) 123 456 789</p>
                <p>+1 (800) 123 456 789</p>
              </div>
            </div> -->
          </div>
        </div>
        <div>
          <small class="primary text-primary font-bold">Contact Us</small>
          <h2 class="text-heading font-bold text-black">Send A Message</h2>
          <p class="my-2">
            We would love to hear from you. Complete the form below
          </p>
          <form @submit.prevent="handleSubmit">
            <div class="grid grid-cols-2 gap-5 mt-8 items-center">
              <div class="col-2">
                <input
                  type="text"
                  id="name"
                  placeholder="Name"
                  v-model="form.name"
                  class="mt-1"
                  required
                />
              </div>
              <div class="col-2">
                <input
                  type="email"
                  id="email"
                  placeholder="Email"
                  v-model="form.email"
                  class="mt-1"
                  required
                />
              </div>
              <div class="col-span-2">
                <textarea
                  id="message"
                  v-model="form.message"
                  placeholder="Message"
                  rows="4"
                  class="mt-1"
                  required
                ></textarea>
              </div>
            </div>
            <div class="mt-5">
              <Button variant="secondary">Submit</Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Button from "../../../../components/Button/index.vue";

export default {
  name: "ContactUs",
  components: { Button },
  data() {
    return {
      form: {
        name: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    handleSubmit() {
      // Handle form submission
      console.log("Form submitted:", this.form);
      // Reset form fields
      this.form.name = "";
      this.form.email = "";
      this.form.message = "";
    },
  },
};
</script>

<style scoped>
.icon-box {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #104863;
  height: 50px;
  font-size: 20px;
  background-color: #f9f9f9;
  border-radius: 50%;
}
</style>
