<template>
  <div class="h-screen flex items-center justify-center bg-[#f7f9fb] relative">
    <div class="login-page-new__main-bg"></div>
    <div
      class="registration-form bg-white p-[45px] border border-[#eaeff5] z-10 mx-4"
    >
      <div>
        <h3 class="text-22 font-bold">Welcome Reversepilot</h3>
        <p class="mt-1">Enter Your Credentials</p>
      </div>
      <form @submit.prevent="handleSubmit">
        <div>
          <label for="username">Username:</label>
          <input type="text" id="username" v-model="username" required />
        </div>
        <div>
          <label for="email">Email:</label>
          <input type="email" id="email" v-model="email" required />
        </div>
        <div>
          <label for="password">Password:</label>
          <input type="password" id="password" v-model="password" required />
        </div>
        <Button class="mt-3 w-full" type="submit">Register</Button>
      </form>
    </div>
  </div>
  <!-- Modal -->
  <div v-if="showModal" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <div class="modal-icon mb-5"><img src="../../assets/check.png" /></div>
      <p class="text-22">Thank you for registering!</p>
    </div>
  </div>
</template>

<script>
import Button from "../../components/Button/index.vue";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Registration",
  components: { Button },
  data() {
    return {
      username: "",
      email: "",
      password: "",
      showModal: false,
    };
  },
  methods: {
    handleSubmit() {
      // Handle form submission logic here (e.g., send data to server)
      console.log("Form submitted", {
        username: this.username,
        email: this.email,
        password: this.password,
      });

      // Show the modal
      this.showModal = true;

      // After a delay, close the modal and redirect to home page
      setTimeout(() => {
        this.closeModal();
        this.$router.push("/home");
      }, 3000);
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
.modal-icon {
  width: 100px;
  height: 100px;
  border: 3px solid #a2e776;
  border-radius: 50%;
  padding: 20px;
}
.registration-form {
  width: 500px;
  border-radius: 8px;
}

.registration-form div {
  margin-bottom: 15px;
}

.registration-form input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  background-color: white;
  padding: 40px;
  border-radius: 5px;
  text-align: center;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.close {
  position: absolute;
  top: 0px;
  right: 20px;
  font-size: 40px;
  cursor: pointer;
  color: #c1c5cd;
}

.login-page-new__main-top-divider {
  height: 100px;
  flex-shrink: 0;
}

.login-page-new__main {
  position: relative;
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 70px;
}

.login-page-new__main-bg {
  pointer-events: none;
}

.login-page-new__main-bg {
  position: absolute;
  height: calc(100% - 17vh);
  width: 100%;
  top: 17vh;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  background: url(./../../assets/login-bg.svg) center 10px no-repeat;
  background-size: cover;
}

.login-page-new__main-bg::before {
  content: "";
  display: block;
  position: absolute;
  width: 200%;
  height: 300%;
  top: -100%;
  left: -50%;
  background: url(../../assets/dots-bg.svg);
  transform: rotate(-28deg);
}

.login-page-new__main-bg::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../../assets/login-shape.svg) center top no-repeat;
  background-size: 100%;
}

@media screen and (height <= 700px) {
  .login-page-new__main-bg::after {
    background-size: 100% 50vh;
  }
}
</style>
