<template>
  <section
    id="home"
    class="hero-section flex items-center justify-center py-10"
  >
    <div class="container">
      <div class="banner-content mx-auto text-center">
        <h1 class="text-heading font-bold text-white">
          The Fully Featured Reverse Mortgage Loan Origination System That Just
          Works
        </h1>
        <p class="mt-5 mb-10 text-white">
          ReversePilot is the ultimate all-in-one solution for reverse mortgage
          origination. Our fully-integrated suite of powerful solutions ensures
          seamless operations, compliance, and efficiency, helping your team
          soar above the competition.
        </p>
        <Button variant="secondary">Sign Up for An Account</Button>
      </div>
      <div class="-mb-[220px]">
        <img src="./../../../../assets/banner-img.png" class="mx-auto" />
      </div>
    </div>
  </section>
</template>

<script>
import Button from "./../../../../components/Button/index.vue";
export default {
  name: "HeroSection",
  components: {
    Button,
  },
};
</script>
<style scoped>
.hero-section {
  padding-top: 150px;
  background: url("./../../../../assets/banner-overlay.svg") no-repeat;
  background-position: bottom center;
  background-size: cover;
}
.banner-content {
  width: 61%;
}
@media (max-width: 1440px) {
  h1 {
    font-size: 32px;
  }
}
@media (max-width: 1200px) {
  .banner-content {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .hero-section {
    padding-top: 100px;
  }
}
</style>
