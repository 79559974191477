<template>
  <div class="banner bg-secondary text-white py-20 text-center pt-[170px]">
    <h1 class="text-[40px] font-bold">{{ title }}</h1>
    <p class="text-lg text-white mt-2">{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: "InnerBanner",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>
