<template>
  <footer class="bg-primary">
    <div class="container pt-50 pb-50 md:pt-70 md:pb-50 lg:pt-70 lg:pb-50">
      <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
        <div>
          <img src="./../../assets/footer-logo.svg" />
          <p class="text-white my-5">
            ReversePilot is the next-generation reverse mortgage loan
            origination system that just works. Founded in 2024, ReversePilot is
            making waves – or should we say turbulence – in the loan origination
            software community.
          </p>
          <ul class="social-icon flex items-center">
            <li>
              <a href="#"
                ><font-awesome-icon :icon="['fab', 'facebook-f']"
              /></a>
            </li>
            <li>
              <a href="#"><font-awesome-icon :icon="['fab', 'twitter']" /></a>
            </li>
            <li>
              <a href="#"><font-awesome-icon :icon="['fab', 'instagram']" /></a>
            </li>
          </ul>
        </div>
        <div class="md:px-0 lg:px-10">
          <h3 class="font-bold text-[19px] text-white mb-5">Quick Links</h3>
          <ul class="block text-left text-white">
            <li>
              <a href="#home" class="font-medium mb-2 block" v-smooth-scroll
                >Home</a
              >
            </li>
            <li>
              <a href="#about" class="font-medium mb-2 block" v-smooth-scroll
                >About Us</a
              >
            </li>
            <li>
              <a href="#services" class="font-medium mb-2 block" v-smooth-scroll
                >Services</a
              >
            </li>
            <li>
              <a
                href="#testimonials"
                class="font-medium mb-2 block"
                v-smooth-scroll
                >Testimonials</a
              >
            </li>
            <li>
              <a href="#blog" class="font-medium mb-2 block" v-smooth-scroll
                >Blog</a
              >
            </li>
            <li>
              <a href="#contact" class="font-medium" v-smooth-scroll
                >Contact Us</a
              >
            </li>
          </ul>
        </div>
        <div>
          <h3 class="font-bold text-[19px] text-white mb-5">Contact Us</h3>
          <div>
            <div class="flex items-center">
              <font-awesome-icon
                icon="fa-solid fa-envelope"
                class="text-secondary w-4"
              />
              <a class="text-white ml-2" href="mailto:info@reversepilot.com"
                >info@reversepilot.com</a
              >
            </div>
            <div class="flex items-center my-2">
              <font-awesome-icon
                icon="fa-solid fa-location-dot"
                class="text-secondary w-4"
              />
              <p class="text-white ml-2">8605 Santa Monica Blvd #25808 <br>West Hollywood, CA 90069-4109 US</p>
            </div>
            <div class="flex items-center">
              <font-awesome-icon
                icon="fa-solid fa-phone"
                class="text-secondary w-4"
              />
              <a class="text-white ml-2" href="tel:808 707 6060"
                >808 707 6060</a
              >
            </div>
          </div>
        </div>
        <div>
          <h3 class="font-bold text-[19px] text-white mb-5">Newsletter</h3>
          <p class="text-white">
            Join our newsletter community to receive regular updates on the
            topics that matter to you.
          </p>
          <Button
            variant="secondary"
            class="inset-0 mt-5"
            @click="openSubscriptionLink"
            >Subscribe
          </Button>
        </div>
      </div>
    </div>
    <div class="copyrights bg-secondary p-4">
      <div class="container">
        <div class="copyright-text flex justify-between items-center">
          <div>
            <p class="text-white font-bold">
              Copyright 2024 <span class="text-primary">ReversePilot</span>. All
              Right Reserved.
            </p>
          </div>
          <div class="flex text-white font-bold">
            <router-link to="/privacy">Privacy Policy</router-link>&nbsp; |
            &nbsp;
            <router-link to="/terms">Terms Of Services</router-link>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Button from "../Button/index.vue";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer",
  components: { Button },
  data() {
    return {
      form: {
        email: "",
      },
    };
  },
  methods: {
    handleSubmit() {
      // Handle form submission
      console.log("Form submitted:", this.form);
      // Reset form fields
      this.form.email = "";
    },
    openSubscriptionLink() {
      window.open(
        "https://ci.criticalimpact.com/sup.cfm?fid=bdc49717c0e29c88",
        "_blank"
      );
    },
  },
};
</script>
<style scoped>
button {
  top: 15px;
  right: 20px;
  font-size: 20px;
}
.social-icon li a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: #e78724;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 1px solid #e78724;
}
.social-icon li a:hover {
  background-color: #e78724;
  color: #fff;
}
.copyrights a:hover {
  color: #104862;
}

@media (max-width: 768px) {
  .copyright-text {
    flex-direction: column;
    text-align: center;
  }
}
</style>
