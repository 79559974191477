import { createApp } from "vue";
import App from "./App.vue";
import "./index.css";
import smoothScroll from "./smoothScroll.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import router from "./router";

library.add(fas);
library.add(fab);

const app = createApp(App)
  .use(router)
  .directive("smooth-scroll", smoothScroll)
  .component("font-awesome-icon", FontAwesomeIcon);

app.mount("#app");
