<template>
  <section
    id="testimonials"
    class="testimonials relative bg-primary flex items-center justify-center py-50 lg:py-100 md:py-70 sm:py-70"
  >
    <div class="container">
      <h2 class="text-heading font-bold text-white text-center">
        What Our Awesome Customers <br />
        Say This...
      </h2>

      <div class="mt-[30px]">
        <div ref="carousel" class="carousel">
          <div
            v-for="item in items"
            :key="item.id"
            class="item box-section rounded-10 pb-9 px-9 shadow-card bg-white"
          >
            <div class="icon-img flex justify-center items-center mx-auto">
              <img src="../../components/img/quote.png" />
            </div>
            <p class="text-center mt-8 mb-4">
              {{ item.description }}
            </p>
            <h3 class="text-center font-semibold text-lg">{{ item.text }}</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from "jquery";
import "slick-carousel";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Testimonials",
  data() {
    return {
      items: [
        {
          id: 1,
          text: "Mr. John Doe",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        },
        {
          id: 2,
          text: "Mr. John Doe",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        },
        {
          id: 3,
          text: "Mr. John Doe",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        },
        {
          id: 4,
          text: "Mr. John Doe",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        },
      ],
    };
  },

  mounted() {
    $(this.$refs.carousel).slick({
      arrows: false,
      dots: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  },
  beforeUnmount() {
    $(this.$refs.carousel).slick("unslick");
  },
};
</script>
<style scoped>
.testimonials {
  z-index: 1;
}

.testimonials::before {
  background: url("./../img/testimonial-bg.png") no-repeat;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
}

.icon-img {
  width: 80px;
  height: 80px;
  border: 3px solid #25556e;
  border-radius: 50%;
  margin-top: -40px;
  background-color: #fff;
}
</style>
